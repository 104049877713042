<template>
  <v-container fluid>
    <v-row>
      <v-col class="col-9 pt-0 pb-2">
        <h1>
          <span v-if="!archived">Current</span>
          <span v-if="archived">Archived</span>
          Timesheets
        </h1>
      </v-col>
      <v-col class="col-3 pt-0 pb-2 text-right">
        <v-btn @click="archived = true" v-if="!archived">Show Archived</v-btn>
        <v-btn @click="archived = false" v-if="archived">Show Current</v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-simple-table
            fixed-header
            height="450px"
        >
          <template v-slot:default>
            <thead>
            <tr>
              <th class="text-left">Name</th>
              <th class="text-left">Job Number</th>
              <th class="text-left">Notes</th>
              <th class="text-left">Date</th>
              <th class="text-left">Start Time</th>
              <th class="text-left">End Time</th>
              <th class="text-left">Time Recorded</th>
              <th class="text-left">Checked</th>
              <th>&nbsp;</th>
            </tr>
            </thead>
            <tbody>
            <tr
                v-for="(sheet, index) in timesheets"
                :key="index"
            >
              <td>{{ sheet.personnel.first_name }} {{ sheet.personnel.last_name }}</td>
              <td>{{ sheet.job_number }}</td>
              <td>{{ sheet.notes }}</td>
              <td>{{ sheet.date | prettyDate }}</td>
              <td>{{ sheet.start_time }}</td>
              <td>{{ sheet.end_time }}</td>
              <td>{{ sheet.timeRecorded }}</td>
              <td>
                <v-checkbox @change="toggleChecked(index)" :ripple="false"  v-model="sheet.checked"></v-checkbox>
              </td>
              <td>
                <v-btn
                    x-small
                    @click="archiveTimesheet(index)">
                  Archive
                </v-btn>
              </td>
            </tr>
            </tbody>
          </template>
        </v-simple-table>
        <v-pagination
            class="pt-4"
            v-model="page"
            :length="length"
            prev-icon="mdi-menu-left"
            next-icon="mdi-menu-right"
            :total-visible="totalVisible"
        ></v-pagination>
      </v-col>
    </v-row>
    <v-dialog
        v-model="warnArchive"
        max-width="290"
    >
      <v-card>
        <v-card-text class="pt-4">
          Are you sure you want to archive this timesheet?
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              text
              @click="confirmArchive"
          >
            Confirm
          </v-btn>
          <v-btn
              text
              @click="warnArchive = false"
          >
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import axios from '../../../axios';

export default {
  name: 'AdminTimesheetsIndex',
  computed: {
    token() {
      return this.$store.state.token;
    },
  },
  data() {
    return {
      archived: 0,
      timesheets: [],
      page: 1,
      length: 0,
      totalVisible: 9,
      warnArchive: false,
      toArchive: -1,
    };
  },
  watch: {
    archived() {
      this.getTimesheets();
    },
    page() {
      this.getTimesheets();
    },
  },
  methods: {
    archiveTimesheet(index) {
      this.toArchive = index;
      this.warnArchive = true;
    },
    confirmArchive() {
      const timesheetId = this.timesheets[this.toArchive].id;
      axios.get(`/timesheets/archive/${timesheetId}.json?token=${this.token}`)
          .then(() => {
            this.timesheets.splice(this.toArchive, 1);
            this.warnArchive = false;
          })
          .catch((error) => {
            console.log(error);
          });
    },
    getTimesheets() {
      let archived = 0;
      if (this.archived === true) archived = 1;
      axios.get(`/timesheets/getAll/${this.page}/${archived}.json?token=${this.token}&archived=0`)
          .then((response) => {
            this.timesheets = response.data.timesheets;
            this.length = response.data.length;
          })
          .catch((error) => {
            console.log(error);
          });
    },
    toggleChecked(index) {
      const id = this.timesheets[index].id;
      axios.get(`/timesheets/toggleChecked/${id}.json?token=${this.token}`);
    },
  },
  mounted() {
    this.getTimesheets();
  },
};
</script>
